/*=================================*/
/*============ Colours ============*/
/*=================================*/
/*BRAND*/
/*BRAND RGB (For opacity)*/
/*INTERFACE*/
/*GREYS*/
.radio-group[data-v-09eda5ed] {
  margin-top: 2em;
}
.radio-container[data-v-09eda5ed] {
  position: relative;
  min-height: 40px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-bottom: 1em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  /* When the checkbox is checked, add a blue background */
  /* Show the checkmark when checked */
}
.radio-container[data-v-09eda5ed]:last-of-type {
  margin-bottom: 0em;
}
.radio-container:hover .checkmark[data-v-09eda5ed] {
  background-color: #f4f4f4;
}
.radio-container.invalid .checkmark[data-v-09eda5ed] {
  -o-border-image: none;
     border-image: none;
  border: 2px solid #D31F1F;
}
.radio-container input:checked ~ .checkmark[data-v-09eda5ed] {
  background-color: #3DAD24;
  border: none;
  width: 40px;
  height: 40px;
  border: 2px solid transparent;
}
.radio-container input:checked ~ .checkmark[data-v-09eda5ed]:after {
  display: block;
}
.radio-container p[data-v-09eda5ed] {
  color: #3D87af;
  margin: 0;
}
.radio-container input[data-v-09eda5ed] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.radio-container .checkmark[data-v-09eda5ed]:after {
  left: 13.5px;
  top: 2.5px;
  width: 5px;
  height: 23px;
  border: solid white;
  border-width: 0 4px 4px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* Create a custom checkbox */
.checkmark[data-v-09eda5ed] {
  height: 40px !important;
  width: 40px !important;
  background: white;
  border: 2px solid #982881;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -o-border-image: linear-gradient(to bottom, #406EB3 0%, #982881 100%);
     border-image: -webkit-gradient(linear, left top, left bottom, from(#406EB3), to(#982881));
     border-image: linear-gradient(to bottom, #406EB3 0%, #982881 100%);
  border-image-slice: 1;
  margin-right: 1em;
  position: relative;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.checkmark[data-v-09eda5ed]:after {
  content: "";
  position: absolute;
  display: none;
}
