/*=================================*/
/*============ Colours ============*/
/*=================================*/
/*BRAND*/
/*BRAND RGB (For opacity)*/
/*INTERFACE*/
/*GREYS*/
.container .text[data-v-23ea6e22] {
  margin-top: 0.5em;
}
.container .text .text-center[data-v-23ea6e22] {
  text-align: center;
}
.container .text p[data-v-23ea6e22] {
  margin-top: 0.1em;
  margin-bottom: 0.1em;
}
.container .text .price[data-v-23ea6e22] {
  text-align: center;
  margin: 0.1em;
  padding-top: 0;
  padding-bottom: 0;
}
.container .text .price p[data-v-23ea6e22] {
  font-size: 5em;
  white-space: pre-wrap;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0.1em;
  margin-bottom: 0.1em;
}
.container .text .price .sub[data-v-23ea6e22] {
  margin-top: -1.1em;
  font-size: 2em;
}
.container .text .text-center[data-v-23ea6e22] {
  text-align: center;
}
.container .text .price-summary[data-v-23ea6e22] {
  padding-top: 0;
  color: #3DAD24;
}
.container .text .smallprint[data-v-23ea6e22] {
  margin-top: 1em;
  text-align: center;
  font-size: 0.5em;
}
.container .text .smallprint span[data-v-23ea6e22] {
  color: #575755;
}
.container .text .smallprint .tc[data-v-23ea6e22] {
  color: #e2a235;
}
.container .text .benefits p[data-v-23ea6e22],
.container .text .benefits span[data-v-23ea6e22] {
  margin-top: 0.5em;
}
