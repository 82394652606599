/*=================================*/
/*============ Colours ============*/
/*=================================*/
/*BRAND*/
/*=================================*/
/*============ Colours ============*/
/*=================================*/
/*BRAND*/
/*BRAND RGB (For opacity)*/
/*INTERFACE*/
/*GREYS*/
/* Remove common styling from a tags */
a {
  text-decoration: none;
  color: inherit;
}
/* Remove autofill colour in chrome */
/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
/*=================================*/
/*========== Animations ==========*/
/*=================================*/
@-webkit-keyframes ripple {
0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
    opacity: 1;
}
20% {
    -webkit-transform: scale(25, 25);
            transform: scale(25, 25);
    opacity: 1;
}
100% {
    opacity: 0;
    -webkit-transform: scale(40, 40);
            transform: scale(40, 40);
}
}
@keyframes ripple {
0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
    opacity: 1;
}
20% {
    -webkit-transform: scale(25, 25);
            transform: scale(25, 25);
    opacity: 1;
}
100% {
    opacity: 0;
    -webkit-transform: scale(40, 40);
            transform: scale(40, 40);
}
}
/*=================================*/
/*============ Buttons ============*/
/*=================================*/
/* Flat Button */
.btnFlat {
  border: none;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  color: #3D87af;
  padding: 6px 14px;
  margin: 6px;
  border-radius: 6px;
  height: 32px;
  font-size: 18px;
  background: transparent;
}
/* To allow ripple */
.btnFlat {
  position: relative;
  overflow: hidden;
}
/* This is the actual ripple */
.btnFlat:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(38, 71, 141, 0.3);
  opacity: 0;
  border-radius: 100%;
  -webkit-transform: scale(1, 1) translate(-50%);
          transform: scale(1, 1) translate(-50%);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}
.btnFlat:focus:not(:active)::after {
  -webkit-animation: ripple 1s ease-out;
          animation: ripple 1s ease-out;
}
.btnFlat:hover {
  background: #f4f4f4;
}
.btnFlat:disabled {
  color: #aaa;
  background: transparent;
  cursor: not-allowed;
  pointer-events: none;
}
/* Raised Button */
.btnRaised {
  border: none;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  color: white;
  padding: 6px 14px;
  margin: 6px;
  border-radius: 6px;
  height: 32px;
  font-size: 18px;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
  background: #26478d;
}
/* To allow ripple */
.btnRaised {
  position: relative;
  overflow: hidden;
}
.btnRaised:active {
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
}
.btnRaised:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.3);
  opacity: 0;
  border-radius: 6px;
  -webkit-transform: scale(1, 1) translate(-50%);
          transform: scale(1, 1) translate(-50%);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}
.btnRaised:focus:not(:active)::after {
  -webkit-animation: ripple 1s ease-out;
          animation: ripple 1s ease-out;
}
.btnRaised:hover {
  background: #406EB3;
}
.btnRaised:disabled {
  color: #FFFFFF;
  background: #aaa;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0.4);
          box-shadow: 0 0 0 rgba(0, 0, 0, 0.4);
  cursor: not-allowed;
  pointer-events: none;
}
.btnDisabled {
  pointer-events: none;
}
.gradientText {
  background: -webkit-linear-gradient(45deg, #982881, #406EB3);
  background-clip: text;
  color: #406EB3;
  position: relative;
  text-shadow: none;
  display: inline-block;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.inputHelp {
  margin: 4px;
}
/*=================================*/
/*============ Pages ============*/
/*=================================*/
.page {
  min-height: 22em;
}
.headerText {
  font-size: 24px;
  margin: 20px 20px 16px 20px;
}
/*=================================*/
/*======== Loading Spinner ========*/
/*=================================*/
.spinner {
  -webkit-animation: rotator 1.4s linear infinite;
          animation: rotator 1.4s linear infinite;
}
@-webkit-keyframes rotator {
0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}
100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
}
}
@keyframes rotator {
0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}
100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
}
}
.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
          animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}
.pathSapphire {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation: dash 1.4s ease-in-out infinite, colorsSapphire 5.6s ease-in-out infinite;
          animation: dash 1.4s ease-in-out infinite, colorsSapphire 5.6s ease-in-out infinite;
}
.lightBlueWhiteText {
  background-color: #406EB3;
  color: #fff;
  border: solid 1px #406EB3;
  border-radius: 5px;
}
.whiteBackgroundVioletText {
  background-color: #fff;
  color: #632678;
}
.violetSubText {
  color: #632678;
  font-size: 16px;
  line-height: 23px;
  font-weight: bold;
}
.middleItem {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  margin: 0 16px;
  padding: 0 26px;
}
@-webkit-keyframes colors {
0% {
    stroke: #ffffff;
}
25% {
    stroke: #ffffff;
}
50% {
    stroke: #ffffff;
}
75% {
    stroke: #ffffff;
}
100% {
    stroke: #ffffff;
}
}
@keyframes colors {
0% {
    stroke: #ffffff;
}
25% {
    stroke: #ffffff;
}
50% {
    stroke: #ffffff;
}
75% {
    stroke: #ffffff;
}
100% {
    stroke: #ffffff;
}
}
@-webkit-keyframes colorsSapphire {
0% {
    stroke: #3D87af;
}
25% {
    stroke: #3D87af;
}
50% {
    stroke: #3D87af;
}
75% {
    stroke: #3D87af;
}
100% {
    stroke: #3D87af;
}
}
@keyframes colorsSapphire {
0% {
    stroke: #3D87af;
}
25% {
    stroke: #3D87af;
}
50% {
    stroke: #3D87af;
}
75% {
    stroke: #3D87af;
}
100% {
    stroke: #3D87af;
}
}
@-webkit-keyframes dash {
0% {
    stroke-dashoffset: 187;
}
50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
}
100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg);
}
}
@keyframes dash {
0% {
    stroke-dashoffset: 187;
}
50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
}
100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg);
}
}
/* Common card style */
.cardBodyText {
  text-align: left;
  font-size: 18px;
  line-height: 24px;
}
.cardContainer {
  padding-top: 24px;
}
.card {
  width: 45%;
  margin: 0 auto 54px auto;
  padding: 32px 32px 24px 32px;
  -webkit-box-shadow: 0 0 8px #aaa;
  box-shadow: 0 0 8px #aaa;
  z-index: 11;
}
.cardErrorCode {
  color: #ccc;
  position: relative;
  left: -14px;
  top: 18px;
  font-size: 10px;
}
.cardNavigationButton {
  margin: 24px 0 -14px -30px;
  float: right;
}
@media only screen and (max-width: 1024px) {
.card {
    width: 80%;
    margin: 0 auto 48px auto;
}
}
@media only screen and (max-width: 560px) {
.cardBodyText {
    font-size: 13px;
    line-height: 22px;
}
.card {
    width: 90%;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
}
.cardNavigationButton {
    margin-top: 12px;
}
.cardErrorCode {
    position: initial;
    left: 0;
    top: 0;
}
}
/*=================================*/
/*========= Media Queries =========*/
/*=================================*/
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  /* Styles */
}
/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) {
  /* Styles */
}
/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */
}
/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Styles */
}
/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* Styles */
}
/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* Styles */
}
/**********
iPad 3
**********/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}
/**********
iPad Pro
**********/
/* iPad pro (portrait and landscape) ----------- */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  /* Styles */
.page {
    min-height: 43.4em;
}
}
/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  /* Styles */
}
/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  /* Styles */
}
/* iPhone 4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}
/* iPhone 5 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}
/* iPhone 6 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}
/* iPhone 6+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}
/* Samsung Galaxy S3 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}
/* Samsung Galaxy S4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}
/* Samsung Galaxy S5 ----------- */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}
