
.confirmationImageContainer {
    margin-top: 20px;
}
.confirmationTextContainer {
    text-align: center;
}
.confirmationImage {
    width: 100px;
    height: 100px;
    padding: 10px;
    display: block;
    margin: auto;
}
