#innerContainer[data-v-63fc98d5] {
  margin: 10px;
}
.card[data-v-63fc98d5] {
  padding-bottom: 32px;
}
#infoText[data-v-63fc98d5] {
  width: 100%;
  font-size: 22px;
  line-height: 1.5em;
  margin-top: 20px;
  text-align: center;
}
#supportImage[data-v-63fc98d5] {
  margin: 0 auto;
  text-align: center;
  width: 120px;
  height: 120px;
}
