/*=================================*/
/*============ Colours ============*/
/*=================================*/
/*BRAND*/
/*BRAND RGB (For opacity)*/
/*INTERFACE*/
/*GREYS*/
.container[data-v-4addbe76] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.container[data-v-4addbe76] .header {
  padding-bottom: 1em;
  text-align: center;
}
.container[data-v-4addbe76] .header .image,
.container[data-v-4addbe76] .header img {
  height: 4em;
  margin-bottom: 1em;
}
.container[data-v-4addbe76] span {
  display: block;
  width: 100%;
  line-height: 1.4em;
}
.container[data-v-4addbe76] .text {
  margin-top: 1em;
}
.container[data-v-4addbe76] .text span {
  color: #3D87af;
  padding-bottom: 0;
  padding-top: 0;
}
.container[data-v-4addbe76] .text > div {
  padding-bottom: 0.5em;
}
.container[data-v-4addbe76] .spacer-line {
  border-bottom: 2px solid #eee;
  margin-left: -2em;
  margin-right: -2em;
}
.container .footer[data-v-4addbe76] {
  float: right;
  padding-top: 2em;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
}
