@font-face {
  font-family: 'experianiconfont';
  src: url(/dist/assets/fonts/experianiconfont.e6b95b2.eot);
  src: url(/dist/assets/fonts/experianiconfont.e6b95b2.eot#iefix) format('embedded-opentype'), url(/dist/assets/fonts/experianiconfont.07e0e02.woff2) format('woff2'), url(/dist/assets/fonts/experianiconfont.f271139.woff) format('woff'), url(/dist/assets/fonts/experianiconfont.8bf4dde.ttf) format('truetype'), url(/dist/assets/images/experianiconfont.svg?812fa74c0876dca189c4a300f552260d#experianiconfont) format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'experianiconfont';
    src: url('../font/experianiconfont.svg?34616403#experianiconfont') format('svg');
  }
}
*/
[class^="exp-icon-"]:before,
[class*=" exp-icon-"]:before {
  font-family: "experianiconfont";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
.exp-icon-calendar-today:before {
  content: '\E800';
}
/* '' */
.exp-icon-help-circle:before {
  content: '\E801';
}
/* '' */
.exp-icon-cash-multiple:before {
  content: '\E802';
}
/* '' */
.exp-icon-check-circle:before {
  content: '\E803';
}
/* '' */
.exp-icon-share:before {
  content: '\E804';
}
/* '' */
.exp-icon-folder-plus:before {
  content: '\E805';
}
/* '' */
.exp-icon-debug-step-out:before {
  content: '\E806';
}
/* '' */
.exp-icon-alert-box:before {
  content: '\E807';
}
/* '' */
.exp-icon-note-text:before {
  content: '\E808';
}
/* '' */
.exp-icon-arrow-left:before {
  content: '\E809';
}
/* '' */
.exp-icon-link-variant:before {
  content: '\E80A';
}
/* '' */
.exp-icon-folder-account:before {
  content: '\E80B';
}
/* '' */
.exp-icon-close-box:before {
  content: '\E80C';
}
/* '' */
.exp-icon-skip-next:before {
  content: '\E80D';
}
/* '' */
.exp-icon-format-list-bulleted:before {
  content: '\E80E';
}
/* '' */
.exp-icon-phone-outgoing:before {
  content: '\E80F';
}
/* '' */
.exp-icon-phone-incoming:before {
  content: '\E810';
}
/* '' */
.exp-icon-magnify:before {
  content: '\E811';
}
/* '' */
.exp-icon-bell:before {
  content: '\E812';
}
/* '' */
.exp-icon-customer-contact-details:before {
  content: '\E813';
}
/* '' */
.exp-icon-table-large:before {
  content: '\E814';
}
/* '' */
.exp-icon-key:before {
  content: '\E815';
}
/* '' */
.exp-icon-currency-usd-off:before {
  content: '\E816';
}
/* '' */
.exp-icon-credit-card:before {
  content: '\E817';
}
/* '' */
.exp-icon-pause-octagon:before {
  content: '\E818';
}
/* '' */
.exp-icon-calendar-clock:before {
  content: '\E819';
}
/* '' */
.exp-icon-stop-circle:before {
  content: '\E81A';
}
/* '' */
.exp-icon-credit-card-plus:before {
  content: '\E81B';
}
/* '' */
.exp-icon-check:before {
  content: '\E81C';
}
/* '' */
.exp-icon-transfer:before {
  content: '\E81D';
}
/* '' */
.exp-icon-eye:before {
  content: '\E81E';
}
/* '' */
.exp-icon-chevron-double-left:before {
  content: '\E81F';
}
/* '' */
.exp-icon-chevron-double-right:before {
  content: '\E820';
}
/* '' */
.exp-icon-chevron-down:before {
  content: '\E821';
}
/* '' */
.exp-icon-chevron-up:before {
  content: '\E822';
}
/* '' */
.exp-icon-chevron-right:before {
  content: '\E823';
}
/* '' */
.exp-icon-chevron-left:before {
  content: '\E824';
}
/* '' */
.exp-icon-filter:before {
  content: '\E825';
}
/* '' */
.exp-icon-pencil-box-outline:before {
  content: '\E826';
}
/* '' */
.exp-icon-plus:before {
  content: '\E827';
}
/* '' */
.exp-icon-phone-hangup:before {
  content: '\E828';
}
/* '' */
.exp-icon-arrow-down:before {
  content: '\E829';
}
/* '' */
.exp-icon-close:before {
  content: '\E82A';
}
/* '' */
.exp-icon-account:before {
  content: '\E82B';
}
/* '' */
.exp-icon-phone-redial:before {
  content: '\E82C';
}
/* '' */
.exp-icon-eye-off:before {
  content: '\E82D';
}
/* '' */
.exp-icon-email-variant:before {
  content: '\E82E';
}
/* '' */
.exp-icon-credit-card-minus:before {
  content: '\E82F';
}
/* '' */
.exp-icon-credit-card-plus-1:before {
  content: '\E830';
}
/* '' */
.exp-icon-account-link:before {
  content: '\E831';
}
/* '' */
.exp-icon-folder-contact:before {
  content: '\E832';
}
/* '' */
.exp-icon-pencil:before {
  content: '\E833';
}
/* '' */
.exp-icon-book-open:before {
  content: '\E834';
}
/* '' */
.exp-icon-account-alert:before {
  content: '\E835';
}
/* '' */
.exp-icon-page-first:before {
  content: '\E836';
}
/* '' */
.exp-icon-page-last:before {
  content: '\E837';
}
/* '' */
.exp-icon-personal-information:before {
  content: '\E838';
}
/* '' */
.exp-icon-fasttrack-to-legal:before {
  content: '\E839';
}
/* '' */
.exp-icon-cursor-move:before {
  content: '\E83A';
}
/* '' */
.exp-icon-letter:before {
  content: '\E83B';
}
/* '' */
.exp-icon-truck-delivery:before {
  content: '\E83C';
}
/* '' */
.exp-icon-team:before {
  content: '\E83D';
}
/* '' */
.exp-icon-team-noleader:before {
  content: '\E83E';
}
/* '' */
.exp-icon-web:before {
  content: '\E83F';
}
/* '' */
.exp-icon-window-maximize:before {
  content: '\E840';
}
/* '' */
.exp-icon-phone-in-talk:before {
  content: '\E841';
}
/* '' */
.exp-icon-download:before {
  content: '\E842';
}
/* '' */
.exp-icon-settings:before {
  content: '\E843';
}
/* '' */
.exp-icon-settings-box:before {
  content: '\E844';
}
/* '' */
.exp-icon-authorise-transfer:before {
  content: '\E845';
}
/* '' */
.exp-icon-arrangement-reducing-payment:before {
  content: '\E846';
}
/* '' */
.exp-icon-arrangement-installment:before {
  content: '\E847';
}
/* '' */
.exp-icon-print-history:before {
  content: '\E848';
}
/* '' */
.exp-icon-suspend-product2:before {
  content: '\E849';
}
/* '' */
.exp-icon-account-close:before {
  content: '\E84A';
}
/* '' */
.exp-icon-file-import:before {
  content: '\E84B';
}
/* '' */
.exp-icon-file-export:before {
  content: '\E84C';
}
/* '' */
.exp-icon-file-view:before {
  content: '\E84D';
}
/* '' */
.exp-icon-printer:before {
  content: '\E84E';
}
/* '' */
.exp-icon-reschedule-arrangement:before {
  content: '\E84F';
}
/* '' */
.exp-icon-dca-allocate:before {
  content: '\E850';
}
/* '' */
.exp-icon-dca-preset:before {
  content: '\E851';
}
/* '' */
.exp-icon-play-circle:before {
  content: '\E852';
}
/* '' */
.exp-icon-edit-history:before {
  content: '\E853';
}
/* '' */
.exp-icon-logout:before {
  content: '\E854';
}
/* '' */
.exp-icon-dca-complete:before {
  content: '\E855';
}
/* '' */
.exp-icon-dca-withdraw:before {
  content: '\E856';
}
/* '' */
.exp-icon-deactivate-product:before {
  content: '\E857';
}
/* '' */
.exp-icon-btn-remove:before {
  content: '\E858';
}
/* '' */
.exp-icon-authorise-writeoff:before {
  content: '\E859';
}
/* '' */
.exp-icon-reactivate-product:before {
  content: '\E85A';
}
/* '' */
.exp-icon-dca-reinstatement:before {
  content: '\E85B';
}
/* '' */
.exp-icon-bar-product:before {
  content: '\E85C';
}
/* '' */
.exp-icon-reinstate-product:before {
  content: '\E85D';
}
/* '' */
.exp-icon-content-save:before {
  content: '\E85E';
}
/* '' */
.exp-icon-content-paste:before {
  content: '\E85F';
}
/* '' */
.exp-icon-tower-import:before {
  content: '\E860';
}
/* '' */
.exp-icon-alert-circle-outline:before {
  content: '\E861';
}
/* '' */
.exp-icon-chart-arc:before {
  content: '\E862';
}
/* '' */
.exp-icon-chart-bar:before {
  content: '\E863';
}
/* '' */
.exp-icon-chart-line:before {
  content: '\E864';
}
/* '' */
.exp-icon-database:before {
  content: '\E865';
}
/* '' */
.exp-icon-database-plus:before {
  content: '\E866';
}
/* '' */
.exp-icon-dots-horizontal:before {
  content: '\E867';
}
/* '' */
.exp-icon-dots-vertical:before {
  content: '\E868';
}
/* '' */
.exp-icon-home:before {
  content: '\E869';
}
/* '' */
.exp-icon-menu:before {
  content: '\E86A';
}
/* '' */
.exp-icon-exclamation:before {
  content: '\E86B';
}
/* '' */
.exp-icon-flag-outline-variant:before {
  content: '\E86C';
}
/* '' */
.exp-icon-refresh:before {
  content: '\E86D';
}
/* '' */
.exp-icon-timer-sand:before {
  content: '\E86E';
}
/* '' */
.exp-icon-star-outline:before {
  content: '\E86F';
}
/* '' */
