/*=================================*/
/*============ Colours ============*/
/*=================================*/
/*BRAND*/
/*=================================*/
/*============ Colours ============*/
/*=================================*/
/*BRAND*/
/*BRAND RGB (For opacity)*/
/*INTERFACE*/
/*GREYS*/
/* Remove common styling from a tags */
a[data-v-56b687e3] {
  text-decoration: none;
  color: inherit;
}
/* Remove autofill colour in chrome */
/* Change the white to any color ;) */
input[data-v-56b687e3]:-webkit-autofill,
input[data-v-56b687e3]:-webkit-autofill:hover,
input[data-v-56b687e3]:-webkit-autofill:focus,
input[data-v-56b687e3]:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
/*=================================*/
/*========== Animations ==========*/
/*=================================*/
@-webkit-keyframes ripple-56b687e3 {
0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
    opacity: 1;
}
20% {
    -webkit-transform: scale(25, 25);
            transform: scale(25, 25);
    opacity: 1;
}
100% {
    opacity: 0;
    -webkit-transform: scale(40, 40);
            transform: scale(40, 40);
}
}
@keyframes ripple-56b687e3 {
0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
    opacity: 1;
}
20% {
    -webkit-transform: scale(25, 25);
            transform: scale(25, 25);
    opacity: 1;
}
100% {
    opacity: 0;
    -webkit-transform: scale(40, 40);
            transform: scale(40, 40);
}
}
/*=================================*/
/*============ Buttons ============*/
/*=================================*/
/* Flat Button */
.btnFlat[data-v-56b687e3] {
  border: none;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  color: #3D87af;
  padding: 6px 14px;
  margin: 6px;
  border-radius: 6px;
  height: 32px;
  font-size: 18px;
  background: transparent;
}
/* To allow ripple */
.btnFlat[data-v-56b687e3] {
  position: relative;
  overflow: hidden;
}
/* This is the actual ripple */
.btnFlat[data-v-56b687e3]:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(38, 71, 141, 0.3);
  opacity: 0;
  border-radius: 100%;
  -webkit-transform: scale(1, 1) translate(-50%);
          transform: scale(1, 1) translate(-50%);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}
.btnFlat[data-v-56b687e3]:focus:not(:active)::after {
  -webkit-animation: ripple-56b687e3 1s ease-out;
          animation: ripple-56b687e3 1s ease-out;
}
.btnFlat[data-v-56b687e3]:hover {
  background: #f4f4f4;
}
.btnFlat[data-v-56b687e3]:disabled {
  color: #aaa;
  background: transparent;
  cursor: not-allowed;
  pointer-events: none;
}
/* Raised Button */
.btnRaised[data-v-56b687e3] {
  border: none;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  color: white;
  padding: 6px 14px;
  margin: 6px;
  border-radius: 6px;
  height: 32px;
  font-size: 18px;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
  background: #26478d;
}
/* To allow ripple */
.btnRaised[data-v-56b687e3] {
  position: relative;
  overflow: hidden;
}
.btnRaised[data-v-56b687e3]:active {
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
}
.btnRaised[data-v-56b687e3]:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.3);
  opacity: 0;
  border-radius: 6px;
  -webkit-transform: scale(1, 1) translate(-50%);
          transform: scale(1, 1) translate(-50%);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}
.btnRaised[data-v-56b687e3]:focus:not(:active)::after {
  -webkit-animation: ripple-56b687e3 1s ease-out;
          animation: ripple-56b687e3 1s ease-out;
}
.btnRaised[data-v-56b687e3]:hover {
  background: #406EB3;
}
.btnRaised[data-v-56b687e3]:disabled {
  color: #FFFFFF;
  background: #aaa;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0.4);
          box-shadow: 0 0 0 rgba(0, 0, 0, 0.4);
  cursor: not-allowed;
  pointer-events: none;
}
.btnDisabled[data-v-56b687e3] {
  pointer-events: none;
}
.gradientText[data-v-56b687e3] {
  background: -webkit-linear-gradient(45deg, #982881, #406EB3);
  background-clip: text;
  color: #406EB3;
  position: relative;
  text-shadow: none;
  display: inline-block;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.inputHelp[data-v-56b687e3] {
  margin: 4px;
}
/*=================================*/
/*============ Pages ============*/
/*=================================*/
.page[data-v-56b687e3] {
  min-height: 22em;
}
.headerText[data-v-56b687e3] {
  font-size: 24px;
  margin: 20px 20px 16px 20px;
}
/*=================================*/
/*======== Loading Spinner ========*/
/*=================================*/
.spinner[data-v-56b687e3] {
  -webkit-animation: rotator-56b687e3 1.4s linear infinite;
          animation: rotator-56b687e3 1.4s linear infinite;
}
@-webkit-keyframes rotator-56b687e3 {
0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}
100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
}
}
@keyframes rotator-56b687e3 {
0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}
100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
}
}
.path[data-v-56b687e3] {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation: dash-56b687e3 1.4s ease-in-out infinite,colors-56b687e3 5.6s ease-in-out infinite;
          animation: dash-56b687e3 1.4s ease-in-out infinite,colors-56b687e3 5.6s ease-in-out infinite;
}
.pathSapphire[data-v-56b687e3] {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation: dash-56b687e3 1.4s ease-in-out infinite,colorsSapphire-56b687e3 5.6s ease-in-out infinite;
          animation: dash-56b687e3 1.4s ease-in-out infinite,colorsSapphire-56b687e3 5.6s ease-in-out infinite;
}
.lightBlueWhiteText[data-v-56b687e3] {
  background-color: #406EB3;
  color: #fff;
  border: solid 1px #406EB3;
  border-radius: 5px;
}
.whiteBackgroundVioletText[data-v-56b687e3] {
  background-color: #fff;
  color: #632678;
}
.violetSubText[data-v-56b687e3] {
  color: #632678;
  font-size: 16px;
  line-height: 23px;
  font-weight: bold;
}
.middleItem[data-v-56b687e3] {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  margin: 0 16px;
  padding: 0 26px;
}
@-webkit-keyframes colors-56b687e3 {
0% {
    stroke: #ffffff;
}
25% {
    stroke: #ffffff;
}
50% {
    stroke: #ffffff;
}
75% {
    stroke: #ffffff;
}
100% {
    stroke: #ffffff;
}
}
@keyframes colors-56b687e3 {
0% {
    stroke: #ffffff;
}
25% {
    stroke: #ffffff;
}
50% {
    stroke: #ffffff;
}
75% {
    stroke: #ffffff;
}
100% {
    stroke: #ffffff;
}
}
@-webkit-keyframes colorsSapphire-56b687e3 {
0% {
    stroke: #3D87af;
}
25% {
    stroke: #3D87af;
}
50% {
    stroke: #3D87af;
}
75% {
    stroke: #3D87af;
}
100% {
    stroke: #3D87af;
}
}
@keyframes colorsSapphire-56b687e3 {
0% {
    stroke: #3D87af;
}
25% {
    stroke: #3D87af;
}
50% {
    stroke: #3D87af;
}
75% {
    stroke: #3D87af;
}
100% {
    stroke: #3D87af;
}
}
@-webkit-keyframes dash-56b687e3 {
0% {
    stroke-dashoffset: 187;
}
50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
}
100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg);
}
}
@keyframes dash-56b687e3 {
0% {
    stroke-dashoffset: 187;
}
50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
}
100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg);
}
}
/* Common card style */
.cardBodyText[data-v-56b687e3] {
  text-align: left;
  font-size: 18px;
  line-height: 24px;
}
.cardContainer[data-v-56b687e3] {
  padding-top: 24px;
}
.card[data-v-56b687e3] {
  width: 45%;
  margin: 0 auto 54px auto;
  padding: 32px 32px 24px 32px;
  -webkit-box-shadow: 0 0 8px #aaa;
  box-shadow: 0 0 8px #aaa;
  z-index: 11;
}
.cardErrorCode[data-v-56b687e3] {
  color: #ccc;
  position: relative;
  left: -14px;
  top: 18px;
  font-size: 10px;
}
.cardNavigationButton[data-v-56b687e3] {
  margin: 24px 0 -14px -30px;
  float: right;
}
@media only screen and (max-width: 1024px) {
.card[data-v-56b687e3] {
    width: 80%;
    margin: 0 auto 48px auto;
}
}
@media only screen and (max-width: 560px) {
.cardBodyText[data-v-56b687e3] {
    font-size: 13px;
    line-height: 22px;
}
.card[data-v-56b687e3] {
    width: 90%;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
}
.cardNavigationButton[data-v-56b687e3] {
    margin-top: 12px;
}
.cardErrorCode[data-v-56b687e3] {
    position: initial;
    left: 0;
    top: 0;
}
}
/*=================================*/
/*========= Media Queries =========*/
/*=================================*/
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  /* Styles */
}
/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) {
  /* Styles */
}
/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */
}
/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Styles */
}
/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* Styles */
}
/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* Styles */
}
/**********
iPad 3
**********/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}
/**********
iPad Pro
**********/
/* iPad pro (portrait and landscape) ----------- */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  /* Styles */
.page[data-v-56b687e3] {
    min-height: 43.4em;
}
}
/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  /* Styles */
}
/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  /* Styles */
}
/* iPhone 4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}
/* iPhone 5 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}
/* iPhone 6 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}
/* iPhone 6+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}
/* Samsung Galaxy S3 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}
/* Samsung Galaxy S4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}
/* Samsung Galaxy S5 ----------- */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}
@font-face {
  font-family: 'experianiconfont';
  src: url(/dist/assets/fonts/experianiconfont.e6b95b2.eot);
  src: url(/dist/assets/fonts/experianiconfont.e6b95b2.eot#iefix) format('embedded-opentype'), url(/dist/assets/fonts/experianiconfont.07e0e02.woff2) format('woff2'), url(/dist/assets/fonts/experianiconfont.f271139.woff) format('woff'), url(/dist/assets/fonts/experianiconfont.8bf4dde.ttf) format('truetype'), url(/dist/assets/images/experianiconfont.svg?812fa74c0876dca189c4a300f552260d#experianiconfont) format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'experianiconfont';
    src: url('../font/experianiconfont.svg?34616403#experianiconfont') format('svg');
  }
}
*/
[class^="exp-icon-"][data-v-56b687e3]:before,
[class*=" exp-icon-"][data-v-56b687e3]:before {
  font-family: "experianiconfont";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
.exp-icon-calendar-today[data-v-56b687e3]:before {
  content: '\E800';
}
/* '' */
.exp-icon-help-circle[data-v-56b687e3]:before {
  content: '\E801';
}
/* '' */
.exp-icon-cash-multiple[data-v-56b687e3]:before {
  content: '\E802';
}
/* '' */
.exp-icon-check-circle[data-v-56b687e3]:before {
  content: '\E803';
}
/* '' */
.exp-icon-share[data-v-56b687e3]:before {
  content: '\E804';
}
/* '' */
.exp-icon-folder-plus[data-v-56b687e3]:before {
  content: '\E805';
}
/* '' */
.exp-icon-debug-step-out[data-v-56b687e3]:before {
  content: '\E806';
}
/* '' */
.exp-icon-alert-box[data-v-56b687e3]:before {
  content: '\E807';
}
/* '' */
.exp-icon-note-text[data-v-56b687e3]:before {
  content: '\E808';
}
/* '' */
.exp-icon-arrow-left[data-v-56b687e3]:before {
  content: '\E809';
}
/* '' */
.exp-icon-link-variant[data-v-56b687e3]:before {
  content: '\E80A';
}
/* '' */
.exp-icon-folder-account[data-v-56b687e3]:before {
  content: '\E80B';
}
/* '' */
.exp-icon-close-box[data-v-56b687e3]:before {
  content: '\E80C';
}
/* '' */
.exp-icon-skip-next[data-v-56b687e3]:before {
  content: '\E80D';
}
/* '' */
.exp-icon-format-list-bulleted[data-v-56b687e3]:before {
  content: '\E80E';
}
/* '' */
.exp-icon-phone-outgoing[data-v-56b687e3]:before {
  content: '\E80F';
}
/* '' */
.exp-icon-phone-incoming[data-v-56b687e3]:before {
  content: '\E810';
}
/* '' */
.exp-icon-magnify[data-v-56b687e3]:before {
  content: '\E811';
}
/* '' */
.exp-icon-bell[data-v-56b687e3]:before {
  content: '\E812';
}
/* '' */
.exp-icon-customer-contact-details[data-v-56b687e3]:before {
  content: '\E813';
}
/* '' */
.exp-icon-table-large[data-v-56b687e3]:before {
  content: '\E814';
}
/* '' */
.exp-icon-key[data-v-56b687e3]:before {
  content: '\E815';
}
/* '' */
.exp-icon-currency-usd-off[data-v-56b687e3]:before {
  content: '\E816';
}
/* '' */
.exp-icon-credit-card[data-v-56b687e3]:before {
  content: '\E817';
}
/* '' */
.exp-icon-pause-octagon[data-v-56b687e3]:before {
  content: '\E818';
}
/* '' */
.exp-icon-calendar-clock[data-v-56b687e3]:before {
  content: '\E819';
}
/* '' */
.exp-icon-stop-circle[data-v-56b687e3]:before {
  content: '\E81A';
}
/* '' */
.exp-icon-credit-card-plus[data-v-56b687e3]:before {
  content: '\E81B';
}
/* '' */
.exp-icon-check[data-v-56b687e3]:before {
  content: '\E81C';
}
/* '' */
.exp-icon-transfer[data-v-56b687e3]:before {
  content: '\E81D';
}
/* '' */
.exp-icon-eye[data-v-56b687e3]:before {
  content: '\E81E';
}
/* '' */
.exp-icon-chevron-double-left[data-v-56b687e3]:before {
  content: '\E81F';
}
/* '' */
.exp-icon-chevron-double-right[data-v-56b687e3]:before {
  content: '\E820';
}
/* '' */
.exp-icon-chevron-down[data-v-56b687e3]:before {
  content: '\E821';
}
/* '' */
.exp-icon-chevron-up[data-v-56b687e3]:before {
  content: '\E822';
}
/* '' */
.exp-icon-chevron-right[data-v-56b687e3]:before {
  content: '\E823';
}
/* '' */
.exp-icon-chevron-left[data-v-56b687e3]:before {
  content: '\E824';
}
/* '' */
.exp-icon-filter[data-v-56b687e3]:before {
  content: '\E825';
}
/* '' */
.exp-icon-pencil-box-outline[data-v-56b687e3]:before {
  content: '\E826';
}
/* '' */
.exp-icon-plus[data-v-56b687e3]:before {
  content: '\E827';
}
/* '' */
.exp-icon-phone-hangup[data-v-56b687e3]:before {
  content: '\E828';
}
/* '' */
.exp-icon-arrow-down[data-v-56b687e3]:before {
  content: '\E829';
}
/* '' */
.exp-icon-close[data-v-56b687e3]:before {
  content: '\E82A';
}
/* '' */
.exp-icon-account[data-v-56b687e3]:before {
  content: '\E82B';
}
/* '' */
.exp-icon-phone-redial[data-v-56b687e3]:before {
  content: '\E82C';
}
/* '' */
.exp-icon-eye-off[data-v-56b687e3]:before {
  content: '\E82D';
}
/* '' */
.exp-icon-email-variant[data-v-56b687e3]:before {
  content: '\E82E';
}
/* '' */
.exp-icon-credit-card-minus[data-v-56b687e3]:before {
  content: '\E82F';
}
/* '' */
.exp-icon-credit-card-plus-1[data-v-56b687e3]:before {
  content: '\E830';
}
/* '' */
.exp-icon-account-link[data-v-56b687e3]:before {
  content: '\E831';
}
/* '' */
.exp-icon-folder-contact[data-v-56b687e3]:before {
  content: '\E832';
}
/* '' */
.exp-icon-pencil[data-v-56b687e3]:before {
  content: '\E833';
}
/* '' */
.exp-icon-book-open[data-v-56b687e3]:before {
  content: '\E834';
}
/* '' */
.exp-icon-account-alert[data-v-56b687e3]:before {
  content: '\E835';
}
/* '' */
.exp-icon-page-first[data-v-56b687e3]:before {
  content: '\E836';
}
/* '' */
.exp-icon-page-last[data-v-56b687e3]:before {
  content: '\E837';
}
/* '' */
.exp-icon-personal-information[data-v-56b687e3]:before {
  content: '\E838';
}
/* '' */
.exp-icon-fasttrack-to-legal[data-v-56b687e3]:before {
  content: '\E839';
}
/* '' */
.exp-icon-cursor-move[data-v-56b687e3]:before {
  content: '\E83A';
}
/* '' */
.exp-icon-letter[data-v-56b687e3]:before {
  content: '\E83B';
}
/* '' */
.exp-icon-truck-delivery[data-v-56b687e3]:before {
  content: '\E83C';
}
/* '' */
.exp-icon-team[data-v-56b687e3]:before {
  content: '\E83D';
}
/* '' */
.exp-icon-team-noleader[data-v-56b687e3]:before {
  content: '\E83E';
}
/* '' */
.exp-icon-web[data-v-56b687e3]:before {
  content: '\E83F';
}
/* '' */
.exp-icon-window-maximize[data-v-56b687e3]:before {
  content: '\E840';
}
/* '' */
.exp-icon-phone-in-talk[data-v-56b687e3]:before {
  content: '\E841';
}
/* '' */
.exp-icon-download[data-v-56b687e3]:before {
  content: '\E842';
}
/* '' */
.exp-icon-settings[data-v-56b687e3]:before {
  content: '\E843';
}
/* '' */
.exp-icon-settings-box[data-v-56b687e3]:before {
  content: '\E844';
}
/* '' */
.exp-icon-authorise-transfer[data-v-56b687e3]:before {
  content: '\E845';
}
/* '' */
.exp-icon-arrangement-reducing-payment[data-v-56b687e3]:before {
  content: '\E846';
}
/* '' */
.exp-icon-arrangement-installment[data-v-56b687e3]:before {
  content: '\E847';
}
/* '' */
.exp-icon-print-history[data-v-56b687e3]:before {
  content: '\E848';
}
/* '' */
.exp-icon-suspend-product2[data-v-56b687e3]:before {
  content: '\E849';
}
/* '' */
.exp-icon-account-close[data-v-56b687e3]:before {
  content: '\E84A';
}
/* '' */
.exp-icon-file-import[data-v-56b687e3]:before {
  content: '\E84B';
}
/* '' */
.exp-icon-file-export[data-v-56b687e3]:before {
  content: '\E84C';
}
/* '' */
.exp-icon-file-view[data-v-56b687e3]:before {
  content: '\E84D';
}
/* '' */
.exp-icon-printer[data-v-56b687e3]:before {
  content: '\E84E';
}
/* '' */
.exp-icon-reschedule-arrangement[data-v-56b687e3]:before {
  content: '\E84F';
}
/* '' */
.exp-icon-dca-allocate[data-v-56b687e3]:before {
  content: '\E850';
}
/* '' */
.exp-icon-dca-preset[data-v-56b687e3]:before {
  content: '\E851';
}
/* '' */
.exp-icon-play-circle[data-v-56b687e3]:before {
  content: '\E852';
}
/* '' */
.exp-icon-edit-history[data-v-56b687e3]:before {
  content: '\E853';
}
/* '' */
.exp-icon-logout[data-v-56b687e3]:before {
  content: '\E854';
}
/* '' */
.exp-icon-dca-complete[data-v-56b687e3]:before {
  content: '\E855';
}
/* '' */
.exp-icon-dca-withdraw[data-v-56b687e3]:before {
  content: '\E856';
}
/* '' */
.exp-icon-deactivate-product[data-v-56b687e3]:before {
  content: '\E857';
}
/* '' */
.exp-icon-btn-remove[data-v-56b687e3]:before {
  content: '\E858';
}
/* '' */
.exp-icon-authorise-writeoff[data-v-56b687e3]:before {
  content: '\E859';
}
/* '' */
.exp-icon-reactivate-product[data-v-56b687e3]:before {
  content: '\E85A';
}
/* '' */
.exp-icon-dca-reinstatement[data-v-56b687e3]:before {
  content: '\E85B';
}
/* '' */
.exp-icon-bar-product[data-v-56b687e3]:before {
  content: '\E85C';
}
/* '' */
.exp-icon-reinstate-product[data-v-56b687e3]:before {
  content: '\E85D';
}
/* '' */
.exp-icon-content-save[data-v-56b687e3]:before {
  content: '\E85E';
}
/* '' */
.exp-icon-content-paste[data-v-56b687e3]:before {
  content: '\E85F';
}
/* '' */
.exp-icon-tower-import[data-v-56b687e3]:before {
  content: '\E860';
}
/* '' */
.exp-icon-alert-circle-outline[data-v-56b687e3]:before {
  content: '\E861';
}
/* '' */
.exp-icon-chart-arc[data-v-56b687e3]:before {
  content: '\E862';
}
/* '' */
.exp-icon-chart-bar[data-v-56b687e3]:before {
  content: '\E863';
}
/* '' */
.exp-icon-chart-line[data-v-56b687e3]:before {
  content: '\E864';
}
/* '' */
.exp-icon-database[data-v-56b687e3]:before {
  content: '\E865';
}
/* '' */
.exp-icon-database-plus[data-v-56b687e3]:before {
  content: '\E866';
}
/* '' */
.exp-icon-dots-horizontal[data-v-56b687e3]:before {
  content: '\E867';
}
/* '' */
.exp-icon-dots-vertical[data-v-56b687e3]:before {
  content: '\E868';
}
/* '' */
.exp-icon-home[data-v-56b687e3]:before {
  content: '\E869';
}
/* '' */
.exp-icon-menu[data-v-56b687e3]:before {
  content: '\E86A';
}
/* '' */
.exp-icon-exclamation[data-v-56b687e3]:before {
  content: '\E86B';
}
/* '' */
.exp-icon-flag-outline-variant[data-v-56b687e3]:before {
  content: '\E86C';
}
/* '' */
.exp-icon-refresh[data-v-56b687e3]:before {
  content: '\E86D';
}
/* '' */
.exp-icon-timer-sand[data-v-56b687e3]:before {
  content: '\E86E';
}
/* '' */
.exp-icon-star-outline[data-v-56b687e3]:before {
  content: '\E86F';
}
/* '' */
.headerSection[data-v-56b687e3] {
  text-align: center;
}
.lightBlue[data-v-56b687e3] {
  color: #406EB3;
}
.iconInfoContainer[data-v-56b687e3] {
  margin: 0 auto;
  max-width: 1020px;
  text-align: center;
}
.nonLimButton[data-v-56b687e3] {
  color: #406EB3;
  font-size: 18px;
  margin: 20px 0 10px 0;
}
.nonLimButton.haveAccount[data-v-56b687e3] {
  font-size: 14px;
}
.sectionDivider[data-v-56b687e3] {
  width: 60%;
  margin: 40px auto;
  border-bottom: solid 1px #eee;
}
.middleInfoItem[data-v-56b687e3] {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}
.signUpSection[data-v-56b687e3] {
  margin-bottom: 56px;
}
/* phone portrait */
@media only screen and (max-width: 568px) {
button[data-v-56b687e3] {
    font-size: 160px !important;
}
.sectionDivider[data-v-56b687e3] {
    margin-top: 20px;
}
.middleInfoItem[data-v-56b687e3] {
    border: none;
}
.heading[data-v-56b687e3] {
    white-space: normal;
}
}
/*medium phone portrait*/
@media only screen and (min-width: 321px) and (max-width: 567px) {
.headerSection[data-v-56b687e3] {
    padding: 0 20px;
}
.middleInfoItem[data-v-56b687e3] {
    border-right: none;
}
}
/*medium phone to tablet landscape */
@media only screen and (min-width: 568px) and (max-width: 1024px) {
.signUpSection[data-v-56b687e3] {
    margin-bottom: 36px;
}
}
/*medium-large phone landscape*/
@media only screen and (min-width: 568px) and (max-width: 736px) {
.middleInfoItem[data-v-56b687e3] {
    border: none;
}
}
