.headerTextContainer {
  width: 50%;
  margin: 0 auto;
}
.registrationHeaderText {
  text-align: center;
  margin: 0 auto 8px auto;
  padding: 4px 0 12px 0;
  font-size: 22px;
  line-height: 28px;
  width: 100%;
}
/* Mobile */
@media only screen and (max-width: 560px) {
.headerTextContainer {
    width: 90%;
    margin: 0 auto;
}
}
