.modal-wrapper .modal-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  max-height: 95vh;
  width: 38vw;
}
@media screen and (max-width: 1025px) {
.modal-wrapper .modal-container {
    width: 75vw;
}
}
@media screen and (max-width: 560px) {
}
.modal-wrapper .modal-container .modal-body {
  padding: 1.5em 2em 0 2em;
}
