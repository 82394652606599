/*=================================*/
/*============ Colours ============*/
/*=================================*/
/*BRAND*/
/*BRAND RGB (For opacity)*/
/*INTERFACE*/
/*GREYS*/
.validation-text[data-v-e8b2b7da] {
  font-size: 0.75em;
  color: #D31F1F;
}
