.modal-mask[data-v-4d0782f0] {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.modal-wrapper[data-v-4d0782f0] {
  vertical-align: middle;
}
.modal-container[data-v-4d0782f0] {
  background-color: #fff;
  border-radius: 6px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 400px;
  position: relative;
  margin: 5% auto 0px;
  margin: 2.5vh auto 0px;
  overflow: auto;
}
.modal-header h3[data-v-4d0782f0] {
  margin-top: 0;
  color: #42b983;
}
.modal-header[data-v-4d0782f0] {
  height: 20%;
}
.modal-body[data-v-4d0782f0] {
  height: 70%;
  padding: 20px 20px 0 20px;
}
.modal-footer[data-v-4d0782f0] {
  height: 10%;
}
.modal-default-button[data-v-4d0782f0] {
  float: right;
}
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.modal-enter[data-v-4d0782f0] {
  opacity: 0;
}
.modal-leave-active[data-v-4d0782f0] {
  opacity: 0;
}
/* phone portrait */
@media only screen and (max-width: 740px) {
.modal-container[data-v-4d0782f0] {
    width: 100% !important;
    left: 0;
    margin-top: 35%;
    overflow: auto;
}
.modal-mask[data-v-4d0782f0] {
    overflow: auto;
}
}
/*iPhone X landscape */
@media only screen and (min-width: 769px) and (max-width: 812px) {
.modal-mask[data-v-4d0782f0] {
    overflow: auto;
}
}
